import styled from 'styled-components';

const BeforeAfterSectionStyle = styled.section`
	--sectionDescriptionMTop: var(--sp5x);
	--sliderSwipeMTop: var(--sp13x);

	--flexBlockWidth: 40.46%;

	margin-top: var(--sectionDistance);

	.section-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.section-title {
		text-align: center;
	}

	.section-description {
		text-align: center;
		margin-top: var(--sectionDescriptionMTop);
	}

	.slider-swipe-global-wrapper {
		margin-top: var(--sliderSwipeMTop);
	}

	.flex-center-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.flex-block {
		width: var(--flexBlockWidth);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--sectionDescriptionMTop: var(--sp4x);
		--sliderSwipeMTop: var(--sp10x);

		--flexBlockWidth: 40.32%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--sectionDescriptionMTop: var(--sp4x);
		--sliderSwipeMTop: var(--sp8x);

		--flexBlockWidth: 40.31%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--sectionDescriptionMTop: var(--sp4x);
		--sliderSwipeMTop: var(--sp8x);

		--flexBlockWidth: 40.4%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXS}) {
		--sectionDescriptionMTop: var(--sp4x);
		--sliderSwipeMTop: var(--sp7x);

		--flexBlockWidth: 40.32%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--sectionDescriptionMTop: var(--sp2-5x);
		--sliderSwipeMTop: var(--sp6x);

		--flexBlockWidth: 49%;

		.empty-column {
			display: none;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--sectionDescriptionMTop: var(--sp2-5x);
		--sliderSwipeMTop: var(--sp6x);

		--flexBlockWidth: 65.7%;

		.side {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.inner-side {
			width: 65.7%;
		}

		.right-side {
			margin-top: var(--sp10x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--sectionDescriptionMTop: var(--sp2-5x);
		--sliderSwipeMTop: var(--sp5x);

		--flexBlockWidth: 100%;

		.right-side {
			margin-top: var(--sp6x);
		}
	}
`;

export default BeforeAfterSectionStyle;
