import { memo, useEffect, useRef } from 'react';
import lottie from 'lottie-web';

const LottieAnimation = memo((props) => {
    //! Refs
    const refs = useRef();
    const anim = useRef();

    useEffect(() => {
        anim.current = lottie.loadAnimation({
            container: refs.current,
            loop: props.loop,
            autoplay: props.autoplay,
            animationData: props.animData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        });
    }, [refs]);

    useEffect(() => {
        anim.current.onComplete = function () {
            if (props.parentMethod) {
                props.parentMethod();
            }
        };

        anim.current.onLoopComplete = () => {
            if (props.onCompletePause) {
                anim.current.pause();
            }
        };

        if (props.isPaused) {
            anim.current.pause();
        }

        if (props.isPlay) {
            anim.current.setDirection(1);
            anim.current.play();
        }
        if (props.isReverse) {
            anim.current.setDirection(-1);
            anim.current.play();
        }
    }, [props.isPlay, props.isPaused, props.isReverse, props.goToAndStop, refs]);

    return (
        <div
            ref={refs}
            className={`lottie-wrap ${props.className || ''}`}
        />
    );
});
export default LottieAnimation;
