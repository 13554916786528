import styled from 'styled-components';

const ButtonStyle = styled.div`
	--defaultBtnPadTB: var(--sp2-5x);
	--defaultBtnPadLR: var(--sp7x);

	--lottieBtnWidth: var(--sp8x);
	--lottieBtnHeight: var(--sp4x);

	&.btn-lottie {
		display: flex;
		align-items: center;
		justify-content: center;

		&.white {
			button,
			a {
				.lottie-wrap {
					svg {
						path {
							fill: var(--backgroundLightColor);
						}
					}
				}
			}
		}

		button,
		a {
			.lottie-wrap {
				width: var(--lottieBtnWidth);
				height: var(--lottieBtnHeight);

				cursor: pointer;
			}
		}

		&.is-reverse {
			button,
			a {
				.lottie-wrap {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&.btn-wrap {
		&.link {
			a,
			button {
				display: flex;
				align-items: center;
				justify-content: center;
				color: var(--primaryColor1);
				font-family: var(--workSans);
				text-transform: uppercase;
				text-align: center;
				cursor: pointer;
			}

			&.large {
				a,
				button {
					font-size: var(--h4);
					line-height: var(--lineHeightM);
					font-weight: 400;
				}

				&.version2 {
					a,
					button {
						font-size: var(--h5);
					}
				}
			}

			&.small {
				a,
				button {
					font-size: var(--p2);
					line-height: var(--lineHeightL);
					font-weight: 500;
				}
			}
		}

		&.default {
			a,
			button {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: var(--defaultBtnPadTB) var(--defaultBtnPadLR);
				font-family: var(--workSans);
				font-weight: 500;
				text-align: center;
				font-size: var(--p2);
				line-height: var(--lineHeightL);
				text-transform: uppercase;
				border-radius: calc(var(--sp15x) / 2);
				cursor: pointer;
			}

			&.primary {
				a,
				button {
					color: var(--backgroundLightColor);
					background-color: var(--primaryColor7);
					border: 1px solid transparent;
					transition: all 0.2s ease-out;

					@media (hover: hover) {
						&:hover {
							color: var(--primaryColor7);
							background-color: transparent;
							border: 1px solid var(--primaryColor7);
						}
					}
				}
			}

			&.secondary {
				a,
				button {
					color: var(--primaryColor1);
					background-color: var(--backgroundLightColor);
					border: 1px solid var(--primaryColor1);
					transition: all 0.2s ease-out;

					@media (hover: hover) {
						&:hover {
							color: var(--backgroundLightColor);
							background-color: var(--primaryColor7);
							border: 1px solid transparent;
						}
					}
				}

				&.white-version {
					a,
					button {
						color: var(--backgroundLightColor);
						border: 1px solid var(--backgroundLightColor);
						background-color: transparent;

						@media (hover: hover) {
							&:hover {
								color: var(--primaryColor1);
								border: 1px solid var(--primaryColor1);
							}
						}
					}
				}

				&.dark-version {
					a,
					button {
						background-color: var(--backgroundDarkColor);

						@media (hover: hover) {
							&:hover {
								color: var(--backgroundLightColor);
								background-color: var(--primaryColor7);
								border: 1px solid transparent;
							}
						}
					}
				}
			}
		}
	}

	&.disabled {
		pointer-events: none;

		a,
		button {
			opacity: 0.5;
			cursor: default;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--defaultBtnPadTB: var(--sp2x);
		--defaultBtnPadLR: var(--sp5x);

		--lottieBtnWidth: var(--sp6x);
		--lottieBtnHeight: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--defaultBtnPadTB: var(--sp1-5x);
		--defaultBtnPadLR: var(--sp4x);

		--lottieBtnWidth: var(--sp5x);
		--lottieBtnHeight: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--defaultBtnPadTB: var(--sp1-5x);
		--defaultBtnPadLR: var(--sp4x);

		--lottieBtnWidth: var(--sp5x);
		--lottieBtnHeight: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXS}) {
		--defaultBtnPadTB: var(--sp1-5x);
		--defaultBtnPadLR: var(--sp4x);

		--lottieBtnWidth: var(--sp5x);
		--lottieBtnHeight: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--defaultBtnPadTB: var(--sp1-5x);
		--defaultBtnPadLR: var(--sp4x);

		--lottieBtnWidth: var(--sp4x);
		--lottieBtnHeight: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--defaultBtnPadTB: 10px;
		--defaultBtnPadLR: var(--sp3x);

		--lottieBtnWidth: var(--sp4x);
		--lottieBtnHeight: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--defaultBtnPadTB: 10px;
		--defaultBtnPadLR: var(--sp3x);

		--lottieBtnWidth: var(--sp4x);
		--lottieBtnHeight: var(--sp2x);
	}
`;
export default ButtonStyle;
