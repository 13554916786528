import { forwardRef, useId } from 'react';
import Link from 'next/link';

const CustomLink = forwardRef(({ ...props }, ref) => {
    const id = useId();

    return (
        <Link
            id={id}
            ref={ref}
            href={props.url}
            scroll={props.scroll}
            target={props.target}
            shallow={props.shallow}
            onClick={props.onClick}
            rel='noopener noreferrer'
            aria-label={props.ariaLabel}
            onMouseOut={props.onMouseOut}
            onMouseOver={props.onMouseOver}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            className={`link-item ${props.className || ''}`}>
            {props.ariaLabel && <span className='sr-only'>{props.ariaLabel}</span>}
            {props.content || props.children}
        </Link>
    );
});

CustomLink.displayName = 'CustomLink';

export default CustomLink;
