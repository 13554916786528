import { memo as Memo, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { usePathname } from 'next/navigation';

//* Helpers
import { withUIContext } from '@/context';

//* Components
import Text from '@/components/global/Text';
import Image from '@/components/global/Image';
import Video from '@/components/global/Video';
import Container from '@/components/global/Container';

//* Style
import HeroSectionStyle from './style';

const HeroSection = Memo(({ title, description, banner_file, className, isHome = false, preloader, winWidth }) => {
    //! Next Navigation
    const pathname = usePathname();

    //! Refs
    const imgRef = useRef();
    const videoAnimRef = useRef();
    const titleRef = useRef();
    const textRef = useRef();
    const chunk1Ref = useRef();
    const chunk2Ref = useRef();
    const homeTextRef = useRef();

    //! Hero Title Home Version
    let wordsArr = title.split(' ');
    const chunk1 = wordsArr.slice(0, 1);
    const chunk2 = wordsArr.slice(1, wordsArr.length);

    //! File Check
    const file = winWidth >= 768 ? banner_file.main : banner_file.mobile;

    //! Animations
    useEffect(() => {
        if (!preloader) {
            if (imgRef.current || videoAnimRef.current) {
                gsap.fromTo(
                    imgRef.current || videoAnimRef.current,
                    {
                        scale: 1.5,
                    },
                    {
                        scale: 1,
                        delay: 0.1,
                        duration: 0.9,
                        ease: 'easeOut',
                    }
                );
            }

            if (titleRef.current) {
                gsap.fromTo(
                    titleRef.current,
                    {
                        css: { transform: 'translate3d(0,100%,0)' },
                    },
                    {
                        css: { transform: 'translate3d(0,0,0)' },
                        delay: 0.7,
                        duration: 0.7,
                        ease: 'easeOut',
                    }
                );
            }

            if (textRef.current) {
                gsap.fromTo(
                    textRef.current,
                    {
                        opacity: 0,
                    },
                    {
                        opacity: 1,
                        delay: 1.2,
                        duration: 0.6,
                        ease: 'easeOut',
                    }
                );
            }

            if (isHome) {
                if (chunk1Ref.current) {
                    gsap.fromTo(
                        chunk1Ref.current,
                        {
                            css: { transform: 'translate3d(0,100%,0)' },
                        },
                        {
                            css: { transform: 'translate3d(0,0,0)' },
                            delay: 0.7,
                            duration: 0.7,
                            ease: 'easeOut',
                        }
                    );
                }

                if (chunk2Ref.current) {
                    gsap.fromTo(
                        chunk2Ref.current,
                        {
                            css: { transform: 'translate3d(0,100%,0)' },
                        },
                        {
                            css: { transform: 'translate3d(0,0,0)' },
                            delay: 0.9,
                            duration: 0.7,
                            ease: 'easeOut',
                        }
                    );
                }

                if (homeTextRef.current) {
                    gsap.fromTo(
                        homeTextRef.current,
                        {
                            opacity: 0,
                        },
                        {
                            opacity: 1,
                            delay: 1.4,
                            duration: 0.5,
                            ease: 'easeOut',
                        }
                    );
                }
            }
        }
    }, [isHome, preloader, pathname]);

    return (
        <HeroSectionStyle className={className || ''}>
            <div className={`relative-wrapper ${isHome ? 'home-version' : ''}`}>
                {file.type === 'image' ? (
                    <Image
                        ref={imgRef}
                        src={file.src}
                        alt={file.alt}
                    />
                ) : (
                    <Video
                        loop
                        autoPlay
                        src={file.src}
                        ref={videoAnimRef}
                    />
                )}

                <div className={`absolute-wrapper`}>
                    <Container
                        row
                        className='hero-content-wrapper'>
                        <div className='hero-content-inner-wrapper'>
                            {isHome ? (
                                <>
                                    <Text
                                        ref={homeTextRef}
                                        className={`p p1 background-light-color workSans-regular uppercase home-text`}
                                        text={description}
                                    />

                                    <div className='overflow-wrapper'>
                                        <Text
                                            tag={'h1'}
                                            ref={chunk1Ref}
                                            className={`h1 background-light-color workSans-regular uppercase chunk1`}
                                            text={chunk1.join(' ')}
                                        />
                                    </div>

                                    {chunk2?.length > 0 ? (
                                        <div className='overflow-wrapper'>
                                            <Text
                                                ref={chunk2Ref}
                                                className={`h1 background-light-color workSans-regular uppercase chunk2`}
                                                text={chunk2.join(' ')}
                                            />
                                        </div>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <div className='overflow-wrapper'>
                                        <Text
                                            tag={'h1'}
                                            ref={titleRef}
                                            className={`h1 background-light-color workSans-regular uppercase title`}
                                            text={title}
                                        />
                                    </div>
                                    <Text
                                        ref={textRef}
                                        className={`p p1 background-light-color workSans-regular uppercase text`}
                                        text={description}
                                    />
                                </>
                            )}
                        </div>
                    </Container>
                </div>
            </div>
        </HeroSectionStyle>
    );
});

export default withUIContext(HeroSection, ['preloader', 'winWidth']);
