import { useMemo, memo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade } from 'swiper';

//* Components
import SwipeSection from '../SwipeSection';
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';

//* Styles
import 'swiper/css';
import 'swiper/css/bundle';
import SliderSwipeSectionStyle from './style';

const SliderSwipeSection = memo(({ title, images }) => {
	//! State
	const [activeIndex, setActiveIndex] = useState(0);

	//! Handle change
	const handleSlideChange = (swiper) => {
		setActiveIndex(swiper.realIndex);
	};

	//! Store slides
	const storeSlides = useMemo(() => {
		return images?.length > 0
			? images.map((item, index) => {
					return (
						<SwiperSlide key={index}>
							<SwipeSection {...item} />
						</SwiperSlide>
					);
			  })
			: null;
	}, [images]);

	return images?.length > 0 ? (
		<SliderSwipeSectionStyle>
			<Text className='p p1 primary-color1 workSans-regular swipe-title'>{title}</Text>

			<Swiper
				loop={true}
				effect={'fade'}
				slidesPerView={'auto'}
				allowTouchMove={false}
				className={'swiper-container'}
				onSlideChange={handleSlideChange}
				modules={[Navigation, EffectFade]}
				navigation={{
					prevEl: '.prev',
					nextEl: '.next',
				}}>
				{storeSlides}

				{images.length > 1 ? (
					<div className='arrows-wrapper'>
						<Button
							btnType='lottie'
							className={`is-reverse prev`}
						/>

						<div className='numbers-wrapper'>
							<Text className='p p1 primary-color1 workSans-medium'>
								{activeIndex + 1} / {images.length}
							</Text>
						</div>

						<Button
							btnType='lottie'
							className={`next`}
						/>
					</div>
				) : null}
			</Swiper>
		</SliderSwipeSectionStyle>
	) : null;
});

export default SliderSwipeSection;
