import styled from 'styled-components';

const HeroSectionStyle = styled.section`
	--heroContentPadBottom: var(--sp8x);
	--heroTextMTB: var(--sp3x);
	--heroTextWidth: 52.6%;
	--imageMinHight: 100vh;
	--imagePaddingTop: 0;

	.relative-wrapper {
		position: relative;
		overflow: hidden;

		&.home-version {
			.image-cont,
			.video-cont {
				&:before {
					background-color: rgb(0, 0, 0, 0.4);
				}
			}
		}
	}

	.absolute-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.image-cont,
	.video-cont {
		transform: scale(1.5);
		transform-origin: center;
		padding-top: var(--imagePaddingTop);
		min-height: var(--imageMinHight);

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.3) 0.01%, rgba(0, 0, 0, 0.15) 10.5%, rgba(0, 0, 0, 0) 31.43%, rgba(0, 0, 0, 0) 68.72%, rgba(0, 0, 0, 0.15) 86.69%, rgba(5, 5, 5, 0.3) 100%);
			z-index: 2;
		}
	}

	.hero-content-wrapper {
		height: 100%;

		.row {
			height: 100%;
		}
	}

	.hero-content-inner-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		padding-bottom: var(--heroContentPadBottom);
	}

	.title,
	.chunk1,
	.chunk2 {
		text-align: center;
		transform: translate3d(0, 100%, 0);
	}

	.text {
		width: var(--heroTextWidth);
		margin-top: var(--heroTextMTB);
		text-align: center;
		opacity: 0;
	}

	.overflow-wrapper {
		overflow: hidden;
	}

	.home-text {
		width: var(--heroTextWidth);
		margin-bottom: var(--heroTextMTB);
		text-align: center;
		opacity: 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--heroContentPadBottom: var(--sp6x);
		--heroTextMTB: var(--sp2x);
		--heroTextWidth: 53.5%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--heroContentPadBottom: var(--sp5x);
		--heroTextMTB: var(--sp1-5x);
		--heroTextWidth: 53.41%;
		--imageMinHight: unset;
		--imagePaddingTop: 50.8%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--heroContentPadBottom: var(--sp4x);
		--heroTextMTB: var(--sp2x);
		--heroTextWidth: 51.7%;
		--imageMinHight: unset;
		--imagePaddingTop: 50.9%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXS}) {
		--heroContentPadBottom: var(--sp4x);
		--heroTextMTB: var(--sp2x);
		--heroTextWidth: 56.4%;
		--imageMinHight: unset;
		--imagePaddingTop: 51%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--heroContentPadBottom: var(--sp3x);
		--heroTextMTB: var(--sp1-5x);
		--heroTextWidth: 68%;
		--imageMinHight: unset;
		--imagePaddingTop: 50.8%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--heroContentPadBottom: var(--sp3x);
		--heroTextMTB: var(--sp1-5x);
		--heroTextWidth: 100%;
		--imageMinHight: unset;
		--imagePaddingTop: 100%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--heroContentPadBottom: var(--sp2x);
		--heroTextMTB: var(--sp1x);
		--heroTextWidth: 100%;
		--imageMinHight: unset;
		--imagePaddingTop: 144.93%;
	}
`;

export default HeroSectionStyle;
