import styled from 'styled-components';

const SliderSwipeSectionStyle = styled.div`
	--arrowsWrapperMTop: var(--sp9x);
	--arrowsItemsDistance: var(--sp4x);

	.arrows-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 calc(-1 * var(--arrowsItemsDistance) / 2);
		margin-top: var(--arrowsWrapperMTop);
	}

	.btn-lottie,
	.numbers-wrapper {
		margin: 0 calc(var(--arrowsItemsDistance) / 2);
	}

	.swipe-title {
		text-align: center;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--arrowsWrapperMTop: var(--sp7x);
		--arrowsItemsDistance: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--arrowsWrapperMTop: var(--sp5x);
		--arrowsItemsDistance: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--arrowsWrapperMTop: var(--sp5x);
		--arrowsItemsDistance: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXS}) {
		--arrowsWrapperMTop: var(--sp4x);
		--arrowsItemsDistance: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--arrowsWrapperMTop: var(--sp4x);
		--arrowsItemsDistance: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--arrowsWrapperMTop: var(--sp3-5x);
		--arrowsItemsDistance: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--arrowsWrapperMTop: var(--sp4x);
		--arrowsItemsDistance: var(--sp2x);
	}
`;

export default SliderSwipeSectionStyle;
