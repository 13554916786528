import { forwardRef, useEffect, useState, useRef, useImperativeHandle } from 'react';

//* Style
import VideoStyle from './style';

const CustomVideo = forwardRef((props, ref) => {
	//! State
	const [check, setCheck] = useState(true);

	//! Ref
	const videoRef = useRef();

	useImperativeHandle(ref, () => videoRef.current, []);

	useEffect(() => {
		if (!props.autoPlay) {
			setCheck(props.autoPlay);
		}
	}, [props.autoPlay]);

	return (
		<VideoStyle
			ref={videoRef}
			className={`video-cont ${props.className || ''}`}>
			<video
				playsInline
				muted={check}
				autoPlay={check}
				loop={props.loop}
				preload={`metadata`}
				controls={props.controls}
				onClick={props.handleClick}>
				<source
					src={`${props.src}#t=0.1`}
					type='video/mp4'
				/>

				<source
					src={`${props.src}#t=0.1`}
					type='video/webm'
				/>
			</video>
		</VideoStyle>
	);
});

CustomVideo.displayName = 'CustomVideo';

export default CustomVideo;
