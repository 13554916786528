'use client';

import { memo as Memo, useEffect } from 'react';

//* Style
import PageStyle from './style';

const Page = Memo((props) => {
	useEffect(() => {
		window.history.scrollRestoration = 'manual';
	}, []);

	return <PageStyle className={`page ${props.className || ''}`}>{props.children}</PageStyle>;
});

Page.displayName = 'Page';

export default Page;
