import { memo } from 'react';

//* Components
import Text from '@/components/global/Text';
import Container from '@/components/global/Container';
import SliderSwipeSection from '../SliderSwipeSection';

//* Style
import BeforeAfterSectionStyle from './style';

const BeforeAfterSection = memo(({ title, description, left_side, right_side, images_title, images, isHome = false }) => {
	return isHome ? (
		<BeforeAfterSectionStyle>
			<div className='before-after-section'>
				<Container row>
					<div className='empty-col col-2 col-t-1 col-ts-12' />

					<div className='col-8 col-t-10 col-ts-12'>
						<div className='section-wrapper'>
							<Text
								className='h6 primary-color1 workSans-medium uppercase section-title'
								text={title}
							/>

							<Text
								className='h5 primary-color1 workSans-regular section-description'
								text={description}
							/>
						</div>
					</div>

					<div className='empty-col col-2 col-t-1 col-ts-12' />
				</Container>

				<div className='slider-swipe-global-wrapper'>
					<Container row>
						<div className='empty-column col-1 col-ts-12' />

						<div className='side left-side col-5 col-t-6 col-ts-12'>
							<div className='inner-side'>
								<SliderSwipeSection {...left_side} />
							</div>
						</div>

						<div className='side right-side col-5 col-t-6 col-ts-12'>
							<div className='inner-side'>
								<SliderSwipeSection {...right_side} />
							</div>
						</div>

						<div className='empty-column col-1 col-ts-12' />
					</Container>
				</div>
			</div>
		</BeforeAfterSectionStyle>
	) : images?.length > 0 ? (
		<BeforeAfterSectionStyle>
			<div className='before-after-section'>
				<Container row>
					<div className='empty-col col-2 col-t-1 col-ts-12' />

					<div className='col-8 col-t-10 col-ts-12'>
						<div className='section-wrapper'>
							<Text
								className='h6 primary-color1 workSans-medium uppercase section-title'
								text={title}
							/>

							<Text
								className='h5 primary-color1 workSans-regular section-description'
								text={description}
							/>
						</div>
					</div>

					<div className='empty-col col-2 col-t-1 col-ts-12' />
				</Container>

				<div className='slider-swipe-global-wrapper'>
					<Container>
						<div className='flex-center-wrapper'>
							<div className='flex-block'>
								<SliderSwipeSection
									title={images_title}
									images={images}
								/>
							</div>
						</div>
					</Container>
				</div>
			</div>
		</BeforeAfterSectionStyle>
	) : null;
});

export default BeforeAfterSection;
