import styled from 'styled-components';

const SwipeSectionStyle = styled.div`
	--imageBorderRadius: var(--sp4x);
	--swipeTitleDistance: var(--sp5x);
	--swipeImageHeight: 20.32vw;

	--handleArrowDistance: var(--sp4x);
	--handlePadding: var(--sp2x) var(--sp4x);
	--handleBorderRadius: var(--sp4x);

	--btnStaticHeight: 58px;

	overflow: hidden;
	isolation: isolate;
	margin-top: var(--swipeTitleDistance);
	border-radius: var(--imageBorderRadius);

	.image-cont {
		--proportion: var(--swipeImageHeight);

		z-index: 0;
	}

	.handle-relative-wrapper {
		position: relative;
		height: 100%;
		border-radius: var(--imageBorderRadius);
		z-index: 5;

		&:before {
			content: '';
			width: 1px;
			top: 0;
			left: 50%;
			margin-right: -50%;
			transform: translateX(-50%);
			height: calc(50% - var(--btnStaticHeight) / 2);
			position: absolute;
			background-color: rgba(0, 0, 0, 0.2);
			z-index: -1;
			pointer-events: none;
			background-color: var(--backgroundLightColor);
		}

		&:after {
			content: '';
			width: 1px;
			bottom: 0;
			left: 50%;
			margin-right: -50%;
			transform: translateX(-50%);
			height: calc(50% - var(--btnStaticHeight) / 2);
			position: absolute;
			background-color: rgba(0, 0, 0, 0.2);
			z-index: -1;
			pointer-events: none;
			background-color: var(--backgroundLightColor);
		}
	}

	.handle-absolute-wrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-right: -50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
	}

	.handle-content {
		display: flex;
		align-items: center;
		padding: var(--handlePadding);
		border-radius: var(--handleBorderRadius);
		border: 1px solid var(--backgroundLightColor);

		i {
			display: block;
			font-size: var(--p1);
			z-index: 9;

			&.is-reverse {
				transform: rotate(-180deg);
			}

			&:before {
				color: var(--backgroundLightColor);
			}

			&:first-child {
				margin-right: calc(var(--handleArrowDistance) / 2);
			}

			&:last-child {
				margin-left: calc(var(--handleArrowDistance) / 2);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--imageBorderRadius: var(--sp3x);
		--swipeTitleDistance: var(--sp4x);

		--handleArrowDistance: var(--sp3x);
		--handlePadding: var(--sp1-5x) var(--sp3x);
		--handleBorderRadius: var(--sp3x);

		--btnStaticHeight: 46px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--imageBorderRadius: var(--sp2x);
		--swipeTitleDistance: var(--sp3x);
		--swipeImageHeight: 20.84vw;

		--handleArrowDistance: var(--sp2-5x);
		--handlePadding: var(--sp1-5x) var(--sp2-5x);
		--handleBorderRadius: var(--sp2-5x);

		--btnStaticHeight: 44px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--imageBorderRadius: var(--sp2x);
		--swipeTitleDistance: var(--sp3x);
		--swipeImageHeight: 20.84vw;

		--handleArrowDistance: var(--sp2-5x);
		--handlePadding: var(--sp1-5x) var(--sp2-5x);
		--handleBorderRadius: var(--sp2-5x);

		--btnStaticHeight: 44px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXS}) {
		--imageBorderRadius: var(--sp2x);
		--swipeTitleDistance: var(--sp2-5x);
		--swipeImageHeight: 21.1vw;

		--handleArrowDistance: var(--sp2-5x);
		--handlePadding: var(--sp1-5x) var(--sp2-5x);
		--handleBorderRadius: var(--sp2-5x);

		--btnStaticHeight: 44px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--imageBorderRadius: var(--sp2x);
		--swipeTitleDistance: var(--sp2x);
		--swipeImageHeight: 25.1vw;

		--handleArrowDistance: var(--sp2-5x);
		--handlePadding: var(--sp1-5x) var(--sp2-5x);
		--handleBorderRadius: var(--sp2-5x);

		--btnStaticHeight: 42px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--imageBorderRadius: var(--sp2x);
		--swipeTitleDistance: var(--sp2x);
		--swipeImageHeight: 32.6vw;

		--handleArrowDistance: var(--sp2-5x);
		--handlePadding: var(--sp1-5x) var(--sp2-5x);
		--handleBorderRadius: var(--sp2-5x);

		--btnStaticHeight: 42px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--imageBorderRadius: var(--sp1-5x);
		--swipeTitleDistance: var(--sp2x);
		--swipeImageHeight: 52.2vw;

		--handleArrowDistance: var(--sp2-5x);
		--handlePadding: var(--sp1-5x) var(--sp2-5x);
		--handleBorderRadius: var(--sp2-5x);

		--btnStaticHeight: 40px;
	}
`;

export default SwipeSectionStyle;
