import { memo } from 'react';
import { ReactCompareSlider } from 'react-compare-slider';

//* Components
import Icon from '@/components/global/Icon';
import Image from '@/components/global/Image';

//* Style
import SwipeSectionStyle from './style';

const SwipeSection = memo(({ className, before_image, after_image }) => {
	return (
		<SwipeSectionStyle className={`${className || ''}`}>
			<ReactCompareSlider
				position={50}
				handle={
					<div className={`handle-relative-wrapper`}>
						<div className='handle-absolute-wrapper'>
							<div className='handle-content'>
								<Icon
									name='arrow-slider'
									className='is-reverse'
								/>

								<Icon name='arrow-slider' />
							</div>
						</div>
					</div>
				}
				itemOne={
					<Image
						src={before_image.src}
						alt={before_image.alt}
					/>
				}
				itemTwo={
					<Image
						src={after_image.src}
						alt={after_image.alt}
					/>
				}
			/>
		</SwipeSectionStyle>
	);
});

export default SwipeSection;
