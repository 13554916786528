import { memo as Memo, useMemo, useState } from 'react';

//* HOC's
import { withLanguageContext, withUIContext } from '@/context';

//* Components
import CustomLink from '../CustomLink';
import LottieAnimation from '../LottieAnimation';

//* Lottie file
import arrow from '../../../lottie/arrow.json';

//* Style
import ButtonStyle from './style';

const Button = Memo(({ winWidth, btnType, text, className, onClick, url, rel, ariaLabel, translate, target, type = 'button', disabled = false, isWhite }) => {
    //! State
    const [arrowLottie, setArrowLottie] = useState();

    const Component = useMemo(() => (url ? CustomLink : 'button'), [url]);

    const customProps = useMemo(() => {
        if (url) {
            return {
                url,
                target,
                rel,
                ariaLabel: ariaLabel ? ariaLabel : text ? translate(text) : url
            }
        } else {
            return { type, disabled }
        }
    }, [url, type, disabled, ariaLabel])

    let btn;

    switch (btnType) {
        case 'lottie':
            btn = (
                <ButtonStyle
                    onClick={onClick}
                    onMouseEnter={() => setArrowLottie(true)}
                    onMouseLeave={() => setArrowLottie(false)}
                    className={`btn-lottie ${isWhite ? 'white' : ''} ${btnType} ${disabled ? 'disabled' : ''} ${className || ''}`}>
                    <Component {...customProps}>
                        {winWidth < 1280 ? (
                            <LottieAnimation
                                loop={false}
                                isPlay={false}
                                autoplay={false}
                                animData={arrow}
                            />
                        ) : (
                            <LottieAnimation
                                loop={false}
                                animData={arrow}
                                autoplay={false}
                                isPlay={arrowLottie}
                                isReverse={!arrowLottie}
                            />
                        )}
                    </Component>
                </ButtonStyle>
            );

            break;

        default:
            btn = (
                <ButtonStyle
                    onClick={onClick}
                    className={`btn-wrap ${className || ''}`}>
                    <Component {...customProps}>{translate(text)}</Component>
                </ButtonStyle>
            );
    }

    return btn;
});

export default withUIContext(withLanguageContext(Button, ['translate']), ['winWidth']);
